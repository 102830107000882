.navbar-container {
    position: relative;
    z-index: 1;
}

.navbar-container::before {
    right: 0;
}

.navbar-container::after {
    left: 0;
}

.navbar-container::before, .navbar-container::after {
    background-color: #f8f9fa!important;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
}

.navbar-light .navbar-nav a {
    color: rgba(255,255,255,.5);
    display: block;
    padding: .5rem 1rem;
}

.navbar-light #logo {
    background-image: url('../../Assets/Imgs/veincenter-logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: transparent;
    padding: 60px;
}

.navbar-toggler {
    border: 0;
}

#responsive-navbar-nav a {
    color: black;
}

@media only screen and (max-width: 425px) {
    .navbar-container {
        padding: 0;
    }
}