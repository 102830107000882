.header {
    background-color: #00697a;
    overflow: hidden;
}

.header-contact {
    display: flex;
    justify-content: space-between;
}

.header-contact p, .header-contact a {
    color: #fff;
    margin: 0;
}

@media only screen and (max-width: 425px) {
    .header-contact {
        display: block;
    }

    .header .carousel-item .w-100 {
        height: 360px;
    }
}