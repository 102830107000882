.footer {
    background-color: #00697a;
    color: white;
    padding-top: 30px;
}

.footer a {
    color: white;
}

.footer-logo {
    background-image: url('../Assets/Imgs/veincenter-logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: transparent!important;
    padding: 60px;
    display: inline-block;
}

.footer-logo:hover, .footer-logo:visited {
    color: transparent;
}

.footer .col-md-7 a {
    display: block;
}

.footer h5 {
    display: block;
    border-bottom: 2px solid white;
}

.footer .social {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.footer .social li  {
    list-style: none;
}

.footer .social .btn-icon {
    background-color: white;
    border: 0;
    border-radius: 50%;
    box-sizing: border-box;
    height: 30px;
    padding: 0;
    width: 30px;
}

.footer .btn-email {
    background-color: rgba(0,0,0, .7);
    color: white;
    margin-bottom: 15px;
    width: 100%;
}

.footer .keep-nav,
.footer .contact-us {
    margin-top: 15px;
}

@media only screen and (max-width: 425px) {
    .header-contact {
        display: block;
    }

    .header .carousel-item .w-100 {
        height: 360px;
    }
}