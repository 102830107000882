.header {
    background-color: #00697a;
    overflow: hidden;
}

.header-contact {
    display: flex;
    justify-content: space-between;
}

.header .carousel-wrapper {
    padding-left: 0;
    padding-right: 0;
}

.header .banner-text {
    display: flex;
    align-items: center;
}

.header-contact p, .header-contact a {
    color: #fff;
    margin: 0;
}

.header .banner-text h2 {
    color: white;
    font-size: 3.5em;
    text-align: right;
}

.header .banner-text strong {
    color: rgba(0, 0, 0, 0.7);
}

.header .carousel-control-prev,
.header .carousel-control-next {
    display: none;
}

.header .carousel-indicators {
    display: flex;
    left: 100px;
    margin-left: 0;
    margin-right: 0;
    right: auto;
}

.header .carousel-indicators li {
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

@media only screen and (max-width: 425px) {
    .header-contact {
        display: block;
    }

    .header .carousel-item .w-100 {
        height: 360px;
    }
}