body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.map {
    width: 100%;
    background-color: antiquewhite;
    display: block;
}

.iframe-map {
    height: 300px;
    width: 100%;
}
.navbar-container {
    position: relative;
    z-index: 1;
}

.navbar-container::before {
    right: 0;
}

.navbar-container::after {
    left: 0;
}

.navbar-container::before, .navbar-container::after {
    background-color: #f8f9fa!important;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;
}

.navbar-light .navbar-nav a {
    color: rgba(255,255,255,.5);
    display: block;
    padding: .5rem 1rem;
}

.navbar-light #logo {
    background-image: url(../../static/media/veincenter-logo.2f2a7d11.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: transparent;
    padding: 60px;
}

.navbar-toggler {
    border: 0;
}

#responsive-navbar-nav a {
    color: black;
}

@media only screen and (max-width: 425px) {
    .navbar-container {
        padding: 0;
    }
}
.carousel-indicators {
    display: none;
}
.header {
    background-color: #00697a;
    overflow: hidden;
}

.header-contact {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.header .carousel-wrapper {
    padding-left: 0;
    padding-right: 0;
}

.header .banner-text {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.header-contact p, .header-contact a {
    color: #fff;
    margin: 0;
}

.header .banner-text h2 {
    color: white;
    font-size: 3.5em;
    text-align: right;
}

.header .banner-text strong {
    color: rgba(0, 0, 0, 0.7);
}

.header .carousel-control-prev,
.header .carousel-control-next {
    display: none;
}

.header .carousel-indicators {
    display: -webkit-flex;
    display: flex;
    left: 100px;
    margin-left: 0;
    margin-right: 0;
    right: auto;
}

.header .carousel-indicators li {
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

@media only screen and (max-width: 425px) {
    .header-contact {
        display: block;
    }

    .header .carousel-item .w-100 {
        height: 360px;
    }
}
.footer {
    background-color: #00697a;
    color: white;
    padding-top: 30px;
}

.footer a {
    color: white;
}

.footer-logo {
    background-image: url(../../static/media/veincenter-logo.2f2a7d11.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: transparent!important;
    padding: 60px;
    display: inline-block;
}

.footer-logo:hover, .footer-logo:visited {
    color: transparent;
}

.footer .col-md-7 a {
    display: block;
}

.footer h5 {
    display: block;
    border-bottom: 2px solid white;
}

.footer .social {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0;
}

.footer .social li  {
    list-style: none;
}

.footer .social .btn-icon {
    background-color: white;
    border: 0;
    border-radius: 50%;
    box-sizing: border-box;
    height: 30px;
    padding: 0;
    width: 30px;
}

.footer .btn-email {
    background-color: rgba(0,0,0, .7);
    color: white;
    margin-bottom: 15px;
    width: 100%;
}

.footer .keep-nav,
.footer .contact-us {
    margin-top: 15px;
}

@media only screen and (max-width: 425px) {
    .header-contact {
        display: block;
    }

    .header .carousel-item .w-100 {
        height: 360px;
    }
}
.card {
    border: 0;
}

.card .carousel img {
    height: 255px;
}

.card-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.team .card,
.clinic .card,
.treatments .card {
    margin-top: 30px;
}

.card .card-text {
    margin-bottom: 0;
}

.card-body .card-title {
    text-decoration: underline;
}

.card-body .card-link {
    display: inline-block;
    margin-top: 10px;
}
.header {
    background-color: #00697a;
    overflow: hidden;
}

.header-contact {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.header-contact p, .header-contact a {
    color: #fff;
    margin: 0;
}

@media only screen and (max-width: 425px) {
    .header-contact {
        display: block;
    }

    .header .carousel-item .w-100 {
        height: 360px;
    }
}
.header {
    background-color: #00697a;
    overflow: hidden;
}

.header-contact {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.header-contact p, .header-contact a {
    color: #fff;
    margin: 0;
}

@media only screen and (max-width: 425px) {
    .header-contact {
        display: block;
    }

    .header .carousel-item .w-100 {
        height: 360px;
    }
}
.header {
    background-color: #00697a;
    overflow: hidden;
}

.header-contact {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.header-contact p, .header-contact a {
    color: #fff;
    margin: 0;
}

@media only screen and (max-width: 425px) {
    .header-contact {
        display: block;
    }

    .header .carousel-item .w-100 {
        height: 360px;
    }
}
.insurer {
    margin-top: 30px;
}

.insurer .card {
    border: 0;
}

.insurer .container {
    margin-bottom: 30px;
    margin-top: 15px;
}
