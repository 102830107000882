.insurer {
    margin-top: 30px;
}

.insurer .card {
    border: 0;
}

.insurer .container {
    margin-bottom: 30px;
    margin-top: 15px;
}