body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.map {
    width: 100%;
    background-color: antiquewhite;
    display: block;
}

.iframe-map {
    height: 300px;
    width: 100%;
}