.card {
    border: 0;
}

.card .carousel img {
    height: 255px;
}

.card-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.team .card,
.clinic .card,
.treatments .card {
    margin-top: 30px;
}

.card .card-text {
    margin-bottom: 0;
}

.card-body .card-title {
    text-decoration: underline;
}

.card-body .card-link {
    display: inline-block;
    margin-top: 10px;
}